import { useNavigate } from 'react-router-dom';

import { Box, Card, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { format } from 'date-fns';

import PATH from '../../routing/routingPaths';
import { useState } from 'react';
import DeleteBudgetDialog from './DeleteBudgetDialog';

const BudgetsTable = ({ items }) => {
  const navigate = useNavigate();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const [openMore, setOpenMore] = useState(null);
  const openMoreAsBool = Boolean(openMore);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [openDeleteBudgetDialog, setOpenDeleteBudgetDialog] = useState(false);

  const handleMoreOpen = (event, budget) => {
    setSelectedBudget(budget)
    setOpenMore(event.currentTarget);
  };

  const handleMoreClose = () => {
    setSelectedBudget(null)
    setOpenMore(null);
  };

  const handleUpdateBudget = () => {
    navigate(`${PATH.root}${PATH.budgets}${PATH.root}${selectedBudget.id}`)
  }

  const handleDeleteBudget = () => {
    setOpenMore(null);
    setOpenDeleteBudgetDialog(true);
  }

  return (
    <>
      <Card>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  Name
                </TableCell>
                {lgUp && (
                  <TableCell>
                    Start date
                  </TableCell>
                )}
                {lgUp && (
                  <TableCell>
                    End date
                  </TableCell>
                )}
                <TableCell>
                  Saldo
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((budget) => {
                const startDate = budget.startDate ? format(new Date(budget.startDate), 'dd/MM/yyyy') : '-';
                const endDate = budget.endDate ? format(new Date(budget.endDate), 'dd/MM/yyyy') : '-';

                return (
                  <TableRow
                    hover
                    key={budget.id}
                  >
                    <TableCell padding="checkbox">
                      <IconButton size="small" onClick={(event) => handleMoreOpen(event, budget)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={openMore}
                        open={openMoreAsBool}
                        onClose={handleMoreClose}
                      >
                        <MenuItem onClick={handleUpdateBudget}>
                          <ListItemIcon>
                            <EditIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography variant="body2">
                              Edit
                            </Typography>
                          </ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleDeleteBudget}>
                          <ListItemIcon>
                            <DeleteIcon fontSize="small" color="error" />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography variant="body2">
                              Delete
                            </Typography>
                          </ListItemText>
                        </MenuItem>
                      </Menu>
                    </TableCell>
                    <TableCell sx={{ wordBreak: 'break-word' }}>
                      {budget.name}
                    </TableCell>
                    {lgUp && (
                      <TableCell>
                        {startDate}
                      </TableCell>
                    )}
                    {lgUp && (
                      <TableCell>
                        {endDate}
                      </TableCell>
                    )}
                    <TableCell>
                      <Typography variant='body2' noWrap>
                        {budget.saldo.value} {budget.saldo.currency}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Card>
      <DeleteBudgetDialog openDialog={openDeleteBudgetDialog} setOpenDialog={setOpenDeleteBudgetDialog} id={selectedBudget?.id} name={selectedBudget?.name} />
    </>
  );
};

export default BudgetsTable;