import { Typography, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

import CURRENCY from './Currency';

const CurrencySelect = ({formik}) => {
  return (
    <FormControl
      variant="filled"
      onBlur={formik.handleBlur('currency')}
      onChange={formik.handleChange('currency')}
      onClick={() => formik.setFieldTouched('currency')}
      error={!!(formik.touched.currency && formik.errors.currency)}>
      <InputLabel id="currency-select-label">Currency</InputLabel>
      <Select
        labelId="currency-select-label"
        label="Currency"
        name="currency"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.currency}>
        <MenuItem value={CURRENCY.PLN}>
          <Typography variant="subtitle2">
            {CURRENCY.PLN}
          </Typography>
        </MenuItem>
        <MenuItem value={CURRENCY.EUR}>
          <Typography variant="subtitle2">
            {CURRENCY.EUR}
          </Typography>
        </MenuItem>
        <MenuItem value={CURRENCY.USD}>
          <Typography variant="subtitle2">
            {CURRENCY.USD}
          </Typography>
        </MenuItem>
        <MenuItem value={CURRENCY.GBP}>
          <Typography variant="subtitle2">
            {CURRENCY.GBP}
          </Typography>
        </MenuItem>
      </Select>
      {!!(formik.touched.currency && formik.errors.currency) &&
        <FormHelperText>
          {formik.errors.currency}
        </FormHelperText>
      }
    </FormControl>
  );
}

export default CurrencySelect;