import { useState } from 'react';

import { Button, Card, CardActions, CardContent, CardHeader, Divider, TextField, Stack, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useUser } from '../../auth/contexts/UserContext';
import { useAuth } from '../../auth/contexts/AuthContext';
import authService from '../../auth/api/authService';

const AccountProfileDetails = () => {
  const [dialogUserName, setDialogUserName] = useState('');
  const [dialogError, setDialogError] = useState('');
  const [open, setOpen] = useState(false);
  const authState = useAuth();
  const userState = useUser();

  const setDialogValues = (values) => {
    setDialogUserName(values.userName);
  }

  const clearDialogValues = () => {
    setDialogUserName('');
  }
  
  const handleClickOpen = (values) => {
    setDialogValues(values);
    setOpen(true);
  };

  const handleClose = () => {
    clearDialogValues('');
    setDialogError('');
    setOpen(false);
  };

  const handleUpdateAccountProfile = async () => {
    try {
      setDialogError('');
      const user = await authState.userManager.getUser();
      await authService.changeUserName(dialogUserName, user.access_token);
      authState.userManager.signoutRedirect();
    } catch(error) {
      setDialogError(error.response.data.message);
    }
  }

  const initialValues = {
    userName: userState.userInfo?.userName ? userState.userInfo?.userName : ''
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      userName: Yup
        .string()
        .max(255)
        .required('Username is required')
    }),
    onSubmit: async (values, helpers) => {
      try {
        handleClickOpen(values);
      }
      catch (error) {
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);

        helpers.setErrors({ submit: error.message });
      }
    }
  });

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Card>
        <CardHeader
          subheader="Update profile"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Stack direction="row" justifyContent="space-between" spacing={3}>
            <Stack direction="column" flex="1">
              <TextField
                error={!!(formik.touched.userName && formik.errors.userName)}
                fullWidth
                helperText={formik.touched.userName && formik.errors.userName}
                label="Username"
                name="userName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.userName}
              />
            </Stack>
          </Stack>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            type="submit">
            Update
          </Button>
        </CardActions>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Username will be changed to <b>{dialogUserName}</b> and then login will be needed again
          </DialogContentText>
          {dialogError && (
            <DialogContentText 
            color="error"
            sx={{ mt: '8px' }}
            variant="body2" >
              <b>{dialogError}</b>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdateAccountProfile} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default AccountProfileDetails;