import { Box, Typography } from "@mui/material";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const MobileIntro = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '4px', marginTop: '32px' }}>
      <Typography variant="h5" color="green.main">
        Plan Expenses
      </Typography>
      <Typography variant="body2" color="green.main">
        Take control of your finances
      </Typography>
      <AccountBalanceIcon sx={{ fontSize: '32px', color: 'green.main' }} />
    </Box>
  );
}

export default MobileIntro;