import { useState } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material";

import budgetGroupService from "./api/budgetGroupService";
import BUDGET_GROUPS_QUERY_KEYS from "./api/budgetGroupsQueryKeys";
import { useAuth } from "../../../auth/contexts/AuthContext";

import size from "../../../theme/size";
import SortableItems from "../../../common/sort/SortableItems";
import sortOrderAssigner from "../../../common/sort/sortOrderAssigner";

const SortBudgetGroupsDialog = ({ openDialog, setOpenDialog, budgetId }) => {
  const authState = useAuth();
  const [sortOrderBudgetGroups, setSortOrderBudgetGroups] = useState();

  const handleClose = () => {
    updateSortOrderBudgetGroupsMutation.reset();
    setOpenDialog(false);
  };

  const sortOrderBudgetGroupsQuery = useQuery({
    queryKey: [BUDGET_GROUPS_QUERY_KEYS.sortBudgetGroups, budgetId],
    queryFn: () => getSortOrderBudgetGroups(),
    enabled: openDialog
  });

  const getSortOrderBudgetGroups = async () => {
    const user = await authState.userManager.getUser();
    const response = await budgetGroupService.getSortOrderBudgetGroups(budgetId, user.access_token);
    setSortOrderBudgetGroups(response.data.budgetGroups);

    return response.data;
  }

  const queryClient = useQueryClient();
  const updateSortOrderBudgetGroupsMutation = useMutation({
    mutationFn: budgetGroupService.updateSortOrderBudgetGroups,
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [BUDGET_GROUPS_QUERY_KEYS.sortBudgetGroups, budgetId], exact: true });
      queryClient.invalidateQueries({ queryKey: [BUDGET_GROUPS_QUERY_KEYS.budgetGroups, budgetId], exact: true });
      handleClose();
    }
  });

  const handleUpdateSortOrderBudgetGroups = async (values) => {
    const user = await authState.userManager.getUser();

    const budgetGroups = sortOrderAssigner.assign(sortOrderBudgetGroups);

    updateSortOrderBudgetGroupsMutation.mutate({
      accessToken: user.access_token,
      budgetId: budgetId,
      budgetGroups: budgetGroups
    });
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
    >
      <Stack direction="column" justifyContent="center" sx={{ p: 1, mr: 1, ml: 1, gap: '16px', minWidth: size.dialogWidth, maxWidth: size.dialogWidth, minHeight: size.dialogHeigth, maxHeight: size.dialogHeigth }}>
        <DialogTitle>
          {"Sort budget groups"}
        </DialogTitle>
        {sortOrderBudgetGroupsQuery.isFetching && (
          <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={100} />
          </DialogContent>
        )}
        {!sortOrderBudgetGroupsQuery.isFetching && sortOrderBudgetGroupsQuery.isSuccess && (
          <DialogContent>
            <SortableItems
              sortableItems={sortOrderBudgetGroups}
              setSortableItems={setSortOrderBudgetGroups} />
          </DialogContent>
        )}
        {(sortOrderBudgetGroupsQuery.isError || updateSortOrderBudgetGroupsMutation.isError) && (
          <Box>
            {sortOrderBudgetGroupsQuery.isError && (
              <DialogContentText
                color="error"
                sx={{ mt: '8px' }}
                variant="body2" >
                {sortOrderBudgetGroupsQuery.error.response
                  ? <b>{sortOrderBudgetGroupsQuery.error.response.data.message}</b>
                  : <b>{sortOrderBudgetGroupsQuery.error.message}</b>}
              </DialogContentText>
            )}
            {updateSortOrderBudgetGroupsMutation.isError && (
              <DialogContentText
                color="error"
                sx={{ mt: '8px' }}
                variant="body2" >
                {updateSortOrderBudgetGroupsMutation.error.response
                  ? <b>{updateSortOrderBudgetGroupsMutation.error.response.data.message}</b>
                  : <b>{updateSortOrderBudgetGroupsMutation.error.message}</b>}
              </DialogContentText>
            )}
          </Box>

        )}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdateSortOrderBudgetGroups} disabled={updateSortOrderBudgetGroupsMutation.isPending} variant="contained">
            {updateSortOrderBudgetGroupsMutation.isPending ? "Updating..." : "Update"}
          </Button>
        </DialogActions>
      </Stack>

    </Dialog>
  );
}

export default SortBudgetGroupsDialog;