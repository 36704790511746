import { useNavigate } from "react-router-dom";

import { Box, Button, Link, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import AuthLayout from './AuthLayout';
import authService from './api/authService';
import PATH from '../routing/routingPaths';
import PasswordField from '../common/PasswordField';

const Login = () => {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup
        .string()
        .max(255)
        .required('Username is required'),
      password: Yup
        .string()
        .max(255)
        .required('Password is required')
    }),
    onSubmit: async (values, helpers) => {
      try {
        await authService.login(values.username, values.password);
        navigate(`${PATH.root}${PATH.authorize}`);
      }
      catch (error) {
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);

        if (error.response) {
          helpers.setErrors({ submit: error.response.data.message });
        }
        else {
          helpers.setErrors({ submit: error.message });
        }
      }
    }
  });

  return (
    <AuthLayout>
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center',  marginTop: '32px', marginBottom: '32px' }}>
        <Box sx={{flex: lgUp ? 0.5 : 0.75, display: 'flex', flexDirection: 'column', gap: '16px'}}>
          <Typography variant={lgUp ? "h4" : "h5"}>
            Login
          </Typography>
          <Typography color="text.secondary" variant="body2">
            Don&apos;t have an account?&nbsp;
            <Link
              style={{ cursor: 'pointer' }}
              underline="hover"
              variant="subtitle2"
              onClick={() => navigate(`${PATH.root}${PATH.register}`)}
            >
              Register
            </Link>
          </Typography>
          <form
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <Stack spacing={3}>
              <TextField
                error={!!(formik.touched.username && formik.errors.username)}
                fullWidth
                helperText={formik.touched.username && formik.errors.username}
                label="Username"
                name="username"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.username}
              />
              <PasswordField formik={formik} formikValue={'password'} label={'Password'} />
            </Stack>
            {formik.errors.submit && (
              <Typography
                color="error"
                sx={{ mt: 3 }}
                variant="body2"
              >
                {formik.errors.submit}
              </Typography>
            )}
            <Button
              fullWidth
              size="large"
              sx={{ mt: 3 }}
              type="submit"
              variant="contained"
            >
              Continue
            </Button>
          </form>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default Login;
