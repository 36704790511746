import { Typography, Button, Card, CardActions, CardContent, CardHeader, Divider, Stack, Box } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import CurrencySelect from '../../common/currency/CurrencySelect';
import CURRENCY_QUERY_KEYS from '../../common/currency/api/currencyQueryKeys';
import currencyService from '../../common/currency/api/currencyService';
import { useAuth } from '../../auth/contexts/AuthContext';

const AccountCurrency = () => {
  const authState = useAuth();

  const defaultCurrencyQuery = useQuery({
    queryKey: [CURRENCY_QUERY_KEYS.defaultCurrency],
    queryFn: () => getDefaultCurrency(),
  });

  const getDefaultCurrency = async () => {
    const user = await authState.userManager.getUser();
    const response = await currencyService.getDefaultCurrency(user.access_token);

    return response.data;
  }

  const queryClient = useQueryClient();
  const updateDefaultCurrencyMutation = useMutation({
    mutationFn: currencyService.updateDefaultCurrency,
    onSuccess: data => {
      queryClient.invalidateQueries([CURRENCY_QUERY_KEYS.defaultCurrency]);
    }
  });

  const handleUpdateDefaultCurrency = async (values) => {
    const user = await authState.userManager.getUser();

    updateDefaultCurrencyMutation.mutate({
      defaultCurrency: values.currency,
      accessToken: user.access_token
    });
  };

  const initialValues = {
    currency: defaultCurrencyQuery.isSuccess ? defaultCurrencyQuery.data.defaultCurrency : ''
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      currency: Yup
        .string()
        .max(10)
        .required('Currency is required')
    }),
    onSubmit: async (values, helpers) => {
      try {
        handleUpdateDefaultCurrency(values);
      }
      catch (error) {
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);

        helpers.setErrors({ submit: error.message });
      }
    }
  });

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Card>
        <CardHeader
          subheader="Update default currency"
          title="Currency"
        />
        <Divider />
        <CardContent>
          <Stack direction="row" justifyContent="space-between" spacing={3}>
            <Stack direction="column" flex="1">
              <CurrencySelect formik={formik} />
            </Stack>
          </Stack>
        </CardContent>
        <Divider />
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>
            {formik.errors.submit && (
              <Typography
                color="error"
                variant="body2"
              >
                {formik.errors.submit}
              </Typography>
            )}
            {defaultCurrencyQuery.isError && (
              <Typography
                color="error"
                variant="body2"
                >
                {defaultCurrencyQuery.error.response
                  ? <b>{defaultCurrencyQuery.error.response.data.message}</b>
                  : <b>{defaultCurrencyQuery.error.message}</b>}
              </Typography>
            )}
            {updateDefaultCurrencyMutation.isError && (
              <Typography
                color="error"
                variant="body2"
                >
                {updateDefaultCurrencyMutation.error.response
                  ? <b>{updateDefaultCurrencyMutation.error.response.data.message}</b>
                  : <b>{updateDefaultCurrencyMutation.error.message}</b>}
              </Typography>
            )}
          </Box>
          <Button type="submit" variant="contained" disabled={updateDefaultCurrencyMutation.isPending} >
            {updateDefaultCurrencyMutation.isPending ? "Updating..." : "Update"}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default AccountCurrency;