import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "./contexts/AuthContext";
import PATH from "../routing/routingPaths";

const ProtectedRoute = ({ children }) => {
  const [isAuthenticationChecked, setAuthenticationChecked] = useState(false);
  const authState = useAuth();

  useEffect(() => {
    authState.userManager.getUser().then(user => {
      const checkIsTokenExpired = (user) => {
        if(!user || !user.expires_at){
          return true;
        }

        const expiresAt = new Date(user.expires_at * 1000);
        const now = new Date();
        const isTokenExpired = now > expiresAt;
      
        return isTokenExpired;
      };

      const isTokenExpired = checkIsTokenExpired(user);

      if(user && !isTokenExpired){
        authState.setAuthenticated(true);
      }
      else{
        authState.setAuthenticated(false);
      }

      setAuthenticationChecked(true);
    });
  });

  if (!isAuthenticationChecked) {
    return;
  }

  if (!authState.isAuthenticated) {
    return <Navigate to={`${PATH.root}${PATH.authorize}`} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;