import { useState } from 'react';

import { Box, Button, CircularProgress, Container, Stack, SvgIcon, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useQuery } from '@tanstack/react-query';

import DashboardLayout from '../../dashboard/DashboardLayout';
import BudgetsTable from './BudgetsTable';
import BudgetsSearch from './BudgetsSearch';
import CreateBudgetDialog from './CreateBudgetDialog';
import budgetsService from './api/budgetsService';
import { useAuth } from '../../auth/contexts/AuthContext';
import BUDGETS_QUERY_KEYS from './api/budgetsQueryKeys';

const Budgets = () => {
  const authState = useAuth();
  const [openCreateBudgetDialog, setOpenCreateBudgetDialog] = useState(false);

  const budgetsQuery = useQuery({
    queryKey: [BUDGETS_QUERY_KEYS.budgets],
    queryFn: () => getUserBudgets()
  });

  const getUserBudgets = async () => {
    const user = await authState.userManager.getUser();
    const budgetsResponse = await budgetsService.getUserBudgets(user.access_token);

    return budgetsResponse.data;
  }

  const addBudget = () => {
    setOpenCreateBudgetDialog(true);
  }

  return (
    <>
      <DashboardLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8
          }}
        >
          <Container maxWidth="xl">
            <Stack spacing={3}>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={4}
              >
                <Stack spacing={1}>
                  <Typography variant="h4">
                    Budgets
                  </Typography>
                </Stack>
                <Button
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <AddIcon />
                    </SvgIcon>
                  )}
                  variant="contained"
                  onClick={addBudget}
                >
                  Add
                </Button>
              </Stack>
              {/* TODO add budget searching <BudgetsSearch /> */}
              {budgetsQuery.isLoading && (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '32px' }}>
                  <CircularProgress size={100} />
                </Box>
              )}
              {budgetsQuery.isError && (
                <Typography
                  color="error"
                  sx={{ mt: 3 }}
                  variant="h6"
                >
                  {budgetsQuery.error.response
                    ? <b>{budgetsQuery.error.response.data.message}</b>
                    : <b>{budgetsQuery.error.message}</b>}
                </Typography>
              )}
              {budgetsQuery.isSuccess && (<BudgetsTable items={budgetsQuery.data} />)}
            </Stack>
          </Container>
        </Box>
        <CreateBudgetDialog openDialog={openCreateBudgetDialog} setOpenDialog={setOpenCreateBudgetDialog} />
      </DashboardLayout>
    </>
  );
};

export default Budgets;
