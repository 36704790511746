import axios from 'axios';
import authSettings from '../authSettings';

const baseUrl = process.env.REACT_APP_AUTH_AUTHORITY_URI;

const authService = {
  login: async function (userName, password) {
    return await axios
      .post(`${baseUrl}Authentication/Login`, {
        userName,
        password
      }, { withCredentials: true });
  },
  getUserInfo: async function (accessToken) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    return await axios.get(authSettings.userinfo_endpoint, config);
  },
  changeUserName: async function (userName, accessToken) {
    const config = {
      withCredentials: true,
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    return await axios
      .post(`${baseUrl}Authentication/ChangeUserName`, {
        userName
      }, config);
  },
  changePassword: async function (currentPassword, newPassword, confirmedNewPassword, accessToken) {
    const config = {
      withCredentials: true,
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    return await axios
      .post(`${baseUrl}Authentication/ChangePassword`, {
        currentPassword,
        newPassword,
        confirmedNewPassword
      }, config);
  }
}

export default authService;