import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URI;

const apiService = {
  register: async function (userName, password, confirmedPassword, defaultCurrency) {
    
    return await axios
      .post(`${baseUrl}Authentication/Register`, {
        userName,
        password,
        confirmedPassword,
        defaultCurrency
      });
  }
}

export default apiService;