import { Box, Container, Stack, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import DashboardLayout from '../../dashboard/DashboardLayout';
import AccountProfile from './AccountProfile';
import AccountProfileDetails from './AccountProfileDetails';
import AccountPassword from './AccountPassword';
import AccountCurrency from './AccountCurrency';

const Account = () => (
  <>
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">
                Account
              </Typography>
            </div>
            <div>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  xs={12}
                  md={6}
                  lg={4}
                >
                  <AccountProfile />
                </Grid>
                <Grid
                  xs={12}
                  md={6}
                  lg={8}
                >
                  <AccountProfileDetails />
                </Grid>
              </Grid>
            </div>
            <AccountCurrency />
            <AccountPassword />
          </Stack>
        </Container>
      </Box>
    </DashboardLayout>
  </>
);

export default Account;
