import { Box, useMediaQuery } from '@mui/material';

import DesktopIntro from './DesktopIntro';
import MobileIntro from './MobileIntro';

const AuthLayout = (props) => {
  const { children } = props;

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return (
    <Box sx={{ flex: lgUp ? 1 : undefined,  display: 'flex', flexDirection: lgUp ? 'row' : 'column' }}>
      {lgUp && (
        <>
          {children}
          <DesktopIntro/>
        </>
      )}
      {!lgUp && (
        <>
          <MobileIntro/>
          {children}
        </>
      )}
    </Box>
  );
};

export default AuthLayout;