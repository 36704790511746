import React, { useContext, useState } from "react"

import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

import authSettings from "../authSettings";

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({children}) => {
  const [userManager, _] = useState(getUserManager());
  const [isAuthenticated, setAuthenticated] = useState(false);

  const state = {
    userManager,
    isAuthenticated,
    setAuthenticated
  };

  return (
    <AuthContext.Provider value={state}>
      {children}
    </AuthContext.Provider>
  )
}

const getUserManager = () => {
  return new UserManager({
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    ...authSettings
  });
}