import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "./contexts/AuthContext";
import Loading from "../common/Loading";
import PATH from "../routing/routingPaths";

const Callback = () => {
  const navigate = useNavigate();
  const authState = useAuth();

  useEffect(() => {
    async function signinRedirectCallback(){
      try {
        await authState.userManager.signinRedirectCallback();
        navigate(PATH.root, { replace: true });
      }
      catch{
        navigate(`${PATH.root}${PATH.login}`)
      }
    }
    signinRedirectCallback();
  });

  return <Loading title={'Logging in'}/>
};

export default Callback;