import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URI;

const budgetGroupService = {
  getBudgetGroups: async function (budgetId, accessToken) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    return await axios.get(`${baseUrl}BudgetGroups/GetBudgetGroups/${budgetId}`, config);
  },
  getSortOrderBudgetGroups: async function (budgetId, accessToken) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    return await axios.get(`${baseUrl}BudgetGroups/GetSortOrderBudgetGroups/${budgetId}`, config);
  },
  createBudgetGroup: async function (values) {
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    var response = await axios.post(`${baseUrl}BudgetGroups/CreateBudgetGroup`, {
      budgetId: values.budgetId,
      name: values.name,
    }, config);

    return response.data;
  },
  updateBudgetGroup: async function (values) {
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    var response = await axios.put(`${baseUrl}BudgetGroups/UpdateBudgetGroup`, {
      id: values.id,
      budgetId: values.budgetId,
      name: values.name,
    }, config);

    return response.data;
  },
  updateSortOrderBudgetGroups: async function (values) {
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    var response = await axios.put(`${baseUrl}BudgetGroups/UpdateSortOrderBudgetGroups`, {
      budgetId: values.budgetId,
      budgetGroups: values.budgetGroups
    }, config);

    return response.data;
  },
  deleteBudgetGroup: async function (values) {
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    const body = {
      id: values.id,
      budgetId: values.budgetId,
    };

    var response = await axios.delete(`${baseUrl}BudgetGroups/DeleteBudgetGroup`, { headers: config.headers, data: body });

    return response.data;
  }
}

export default budgetGroupService;