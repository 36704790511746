import { useState } from 'react';

import { Card, CardContent, Divider, IconButton, Typography, Menu, MenuItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SwapVertIcon from '@mui/icons-material/SwapVert';

import DeleteBudgetGroupDialog from './DeleteBudgetGroupDialog';
import UpdateBudgetGroupDialog from './UpdateBudgetGroupDialog';
import BudgetGroupPositions from '../budgetGroupPosition/BudgetGroupPositions';
import SortBudgetGroupPositionsDialog from '../budgetGroupPosition/SortBudgetGroupPositionsDialog';

const BudgetGroup = ({ budgetId, budgetGroup }) => {
  const [openDeleteBudgetGroupDialog, setOpenDeleteBudgetGroupDialog] = useState(false);
  const [openUpdateBudgetGroupDialog, setOpenUpdateBudgetGroupDialog] = useState(false);
  const [openSortOrderBudgetGroupPositionsDialog, setOpenSortOrderBudgetGroupPositionsDialog] = useState(false);
  const [openMore, setOpenMore] = useState(null);

  const handleMoreOpen = (event) => {
    setOpenMore(event.currentTarget);
  };

  const handleMoreClose = () => {
    setOpenMore(null);
  };

  const handleDeleteBudgetGroup = () => {
    handleMoreClose();
    setOpenDeleteBudgetGroupDialog(true);
  }

  const handleUpdateBudgetGroup = () => {
    handleMoreClose();
    setOpenUpdateBudgetGroupDialog(true);
  }

  const handleSortBudgetGroupPositions = () => {
    handleMoreClose();
    setOpenSortOrderBudgetGroupPositionsDialog(true);
  }

  return (
    <>
      <Card>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: '8px' }}>
            <Typography variant="h6" sx={{ wordBreak: 'break-word' }}>
              {budgetGroup.name}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flex: 1 }}>
              <Typography>
                {budgetGroup.saldo.value}&nbsp;
              </Typography>
              <Typography>
                {budgetGroup.saldo.currency}
              </Typography>
              <IconButton size="small" onClick={handleMoreOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={openMore}
                open={Boolean(openMore)}
                onClose={handleMoreClose}
              >
                <MenuItem onClick={handleSortBudgetGroupPositions}>
                  <ListItemIcon>
                    <SwapVertIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body2">
                      Sort positions
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleUpdateBudgetGroup}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body2">
                      Edit
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDeleteBudgetGroup}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" color="error" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body2">
                      Delete
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <Divider />
          <BudgetGroupPositions budgetId={budgetId} currency={budgetGroup.saldo.currency} budgetGroupId={budgetGroup.id} positions={budgetGroup.positions} />
        </CardContent>
      </Card>
      <DeleteBudgetGroupDialog openDialog={openDeleteBudgetGroupDialog} setOpenDialog={setOpenDeleteBudgetGroupDialog} id={budgetGroup.id} budgetId={budgetId} name={budgetGroup.name} />
      <UpdateBudgetGroupDialog openDialog={openUpdateBudgetGroupDialog} setOpenDialog={setOpenUpdateBudgetGroupDialog} id={budgetGroup.id} budgetId={budgetId} name={budgetGroup.name} />
      <SortBudgetGroupPositionsDialog openDialog={openSortOrderBudgetGroupPositionsDialog} setOpenDialog={setOpenSortOrderBudgetGroupPositionsDialog} budgetId={budgetId} budgetGroupId={budgetGroup.id} />
    </>
  );
};

export default BudgetGroup;