import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URI;

const budgetGroupPositionService = {
  getSortOrderBudgetGroupPositions: async function (budgetId, budgetGroupId, accessToken) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {
        budgetId: budgetId,
        budgetGroupId: budgetGroupId
      }
    };

    return await axios.get(`${baseUrl}BudgetGroupPositions/GetSortOrderBudgetGroupPositions`, config);
  },
  createBudgetGroupPosition: async function (values) {
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    var response = await axios.post(`${baseUrl}BudgetGroupPositions/CreateBudgetGroupPosition`, {
      budgetGroupId: values.budgetGroupId,
      budgetId: values.budgetId,
      name: values.name,
      price: values.price,
      type: values.type
    }, config);

    return response.data;
  },
  updateBudgetGroupPosition: async function (values) {
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    var response = await axios.put(`${baseUrl}BudgetGroupPositions/UpdateBudgetGroupPosition`, {
      id: values.id,
      budgetGroupId: values.budgetGroupId,
      budgetId: values.budgetId,
      name: values.name,
      price: values.price,
      type: values.type
    }, config);

    return response.data;
  },
  updateSortOrderBudgetGroupPositions: async function (values) {
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    var response = await axios.put(`${baseUrl}BudgetGroupPositions/UpdateSortOrderBudgetGroupPositions`, {
      budgetId: values.budgetId,
      budgetGroupId: values.budgetGroupId,
      positions: values.positions
    }, config);

    return response.data;
  },
  deleteBudgetGroupPosition: async function (values) {
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    const body = {
      id: values.id,
      budgetGroupId: values.budgetGroupId,
      budgetId: values.budgetId
    };

    var response = await axios.delete(`${baseUrl}BudgetGroupPositions/DeleteBudgetGroupPosition`, { headers: config.headers, data: body });

    return response.data;
  }
}

export default budgetGroupPositionService;