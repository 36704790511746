import { DndContext, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { Box, Card, CardContent, Typography } from "@mui/material";

import SortableItem from "./SortableItem";

const SortableItems = ({ sortableItems, setSortableItems }) => {
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor)
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setSortableItems(sortableItems => {
        const activeIndex = sortableItems.findIndex(item => item.id === active.id);
        const overIndex = sortableItems.findIndex(item => item.id === over.id);

        return arrayMove(sortableItems, activeIndex, overIndex);
      });
    }
  };

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}>
      <SortableContext items={sortableItems} strategy={verticalListSortingStrategy}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px', marginTop: '16px' }}>
          {sortableItems.map(sortableItem => {
            return (
              <SortableItem key={sortableItem.id} id={sortableItem.id}>
                <Card>
                  <CardContent>
                    <Typography align="center">
                      {sortableItem.name}
                    </Typography>
                  </CardContent>
                </Card>
              </SortableItem>
            )
          })}
        </Box>
      </SortableContext>
    </DndContext>
  )
}

export default SortableItems;