import { useNavigate } from "react-router-dom";

import { Button, Stack, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import PATH from "../../routing/routingPaths";
import BUDGETS_QUERY_KEYS from "./api/budgetsQueryKeys";
import budgetsService from "./api/budgetsService";
import { useAuth } from "../../auth/contexts/AuthContext";
import dateValidator from "../../common/date/dateValidator";
import size from "../../theme/size";
import CurrencySelect from "../../common/currency/CurrencySelect";
import CURRENCY_QUERY_KEYS from "../../common/currency/api/currencyQueryKeys";
import currencyService from "../../common/currency/api/currencyService";

const CreateBudgetDialog = ({ openDialog, setOpenDialog }) => {
  const authState = useAuth();
  const navigate = useNavigate();

  const handleClose = () => {
    formik.resetForm();
    createBudgetMutation.reset();
    setOpenDialog(false);
  };

  const defaultCurrencyQuery = useQuery({
    queryKey: [CURRENCY_QUERY_KEYS.defaultCurrency],
    queryFn: () => getDefaultCurrency(),
  });

  const getDefaultCurrency = async () => {
    const user = await authState.userManager.getUser();
    const response = await currencyService.getDefaultCurrency(user.access_token);

    return response.data;
  }

  const queryClient = useQueryClient();
  const createBudgetMutation = useMutation({
    mutationFn: budgetsService.createBudget,
    onSuccess: data => {
      queryClient.invalidateQueries({queryKey: [BUDGETS_QUERY_KEYS.budgets], exact: true })
      navigate(`${PATH.root}${PATH.budgets}${PATH.root}${data.id}`)
    }
  });

  const handleCreateBudget = async (values) => {
    const user = await authState.userManager.getUser();

    createBudgetMutation.mutate({
      name: values.budgetName,
      currency: values.currency,
      startDate: values.startDate,
      endDate: values.endDate,
      accessToken: user.access_token
    });
  };

  const initialValues = {
    budgetName: '',
    currency: defaultCurrencyQuery.isSuccess ? defaultCurrencyQuery.data.defaultCurrency : '',
    startDate: null,
    endDate: null
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      budgetName: Yup
        .string()
        .max(255)
        .required('Name is required'),
      currency: Yup
        .string()
        .max(10)
        .required('Currency is required'),
      startDate: Yup
        .date()
        .optional()
        .nullable(),
      endDate: Yup
        .date()
        .optional()
        .nullable()
        .when('startDate', {
          is: (startDate) => dateValidator.isValid(startDate),
          then: (schema) => schema.min(Yup.ref('startDate'), 'End date cannot be earlier than start date'),
          otherwise: (schema) => schema
        })
    }),
    onSubmit: async (values, helpers) => {
      try {
        handleCreateBudget(values);
      }
      catch (error) {
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);

        helpers.setErrors({ submit: error.message });
      }
    }
  });

  const handleFormikDateErrors = (date, error) => {
    if (!error) {
      return error;
    }

    if (!dateValidator.isValid(date)) {
      return "Specify valid date";
    }

    return error;
  }

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" justifyContent="center" sx={{ p: 1, mr: 1, ml: 1, minWidth: size.dialogWidth }}>
          <DialogTitle sx={{ mt: 1 }}>
            {"Create budget"}
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" justifyContent="center" spacing={3} sx={{ mt: 3 }}>
              <TextField
                error={!!(formik.touched.budgetName && formik.errors.budgetName)}
                helperText={formik.touched.budgetName && formik.errors.budgetName}
                label="Name"
                name="budgetName"
                onChange={formik.handleChange}
                type="text"
                value={formik.values.budgetName}
              />
              <CurrencySelect formik={formik} />
              <DatePicker
                label="Start date"
                name="startDate"
                onChange={value => formik.setFieldValue('startDate', value)}
                value={formik.values.startDate}
                slotProps={{
                  field: { clearable: true },
                  actionBar: {
                    actions: ['cancel', 'clear', 'accept']
                  },
                  textField: {
                    helperText: handleFormikDateErrors(formik.values.startDate, formik.errors.startDate),
                  },
                }}
              />
              <DatePicker
                label="End date"
                name="endDate"
                onChange={value => formik.setFieldValue('endDate', value)}
                value={formik.values.endDate}
                minDate={formik.values.startDate}
                slotProps={{
                  field: { clearable: true },
                  actionBar: {
                    actions: ['cancel', 'clear', 'accept']
                  },
                  textField: {
                    helperText: handleFormikDateErrors(formik.values.endDate, formik.errors.endDate),
                  },
                }}
              />
              {formik.errors.submit && (
                <DialogContentText
                  color="error"
                  sx={{ mt: '8px' }}
                  variant="body2"
                >
                  {formik.errors.submit}
                </DialogContentText>
              )}
              {defaultCurrencyQuery.isError && (
                <DialogContentText
                  color="error"
                  sx={{ mt: '8px' }}
                  variant="body2" >
                  {defaultCurrencyQuery.error.response
                    ? <b>{defaultCurrencyQuery.error.response.data.message}</b>
                    : <b>{defaultCurrencyQuery.error.message}</b>}
                </DialogContentText>
              )}
              {createBudgetMutation.isError && (
                <DialogContentText
                  color="error"
                  sx={{ mt: '8px' }}
                  variant="body2" >
                  {createBudgetMutation.error.response
                    ? <b>{createBudgetMutation.error.response.data.message}</b>
                    : <b>{createBudgetMutation.error.message}</b>}
                </DialogContentText>
              )}
            </Stack>
          </DialogContent>
        </Stack>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={createBudgetMutation.isPending} type="submit" variant="contained">
            {createBudgetMutation.isPending ? "Creating..." : "Create"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreateBudgetDialog;