import { Box, CircularProgress, Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const Loading = ({title}) => {
  return (
    <>
      <Box sx={{
        background: 'radial-gradient(50% 50% at 50% 50%, #122647 0%, #090E23 100%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}>
        <Box sx={{ color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '32px' }}>
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Typography variant="subtitle1" color="green.main">Plan Expenses</Typography>
            <AccountBalanceIcon fontSize='small'/>
          </Box>
          <Typography variant='h4'>{title}</Typography>
          <CircularProgress size={100} />
        </Box>
      </Box>
    </>
  );
}

export default Loading;