import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URI;

const budgetsService = {
  getUserBudgets: async function (accessToken) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    return await axios.get(`${baseUrl}Budgets/GetUserBudgets`, config);
  },
  createBudget: async function (values) {
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    var response = await axios.post(`${baseUrl}Budgets/CreateBudget`, {
      name: values.name,
      currency: values.currency,
      startDate: values.startDate,
      endDate: values.endDate
    }, config);

    return response.data;
  },
  updateBudget: async function (values) {
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    var response = await axios.put(`${baseUrl}Budgets/UpdateBudget`, {
      id: values.id,
      name: values.name,
      startDate: values.startDate,
      endDate: values.endDate
    }, config);

    return response.data;
  },
  deleteBudget: async function (values) {
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    var response = await axios.delete(`${baseUrl}Budgets/DeleteBudget/${values.id}`, config);

    return response.data;
  }
}

export default budgetsService;