import { Box, Card, CardContent, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useUser } from '../../auth/contexts/UserContext';

const AccountProfile = () => {
  const userState = useUser();

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <AccountCircleIcon
            sx={{
              height: 80,
              mb: 2,
              width: 80
            }}
          />
          <Typography
            variant="h5"
          >
            {userState.userInfo && userState.userInfo.userName}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AccountProfile;