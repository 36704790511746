import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URI;

const currencyService = {
  getDefaultCurrency: async function (accessToken) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    return await axios.get(`${baseUrl}Accounts/GetDefaultCurrency`, config);
  },
  updateDefaultCurrency: async function (values){
    const config = {
      headers: { Authorization: `Bearer ${values.accessToken}` }
    };

    return await axios.put(`${baseUrl}Accounts/UpdateDefaultCurrency`, {
      defaultCurrency: values.defaultCurrency
    }, config);
  }
}

export default currencyService;