import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Box, Typography, Unstable_Grid2 as Grid, CircularProgress, Button, Stack, SvgIcon, IconButton, Menu, MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useAuth } from '../../../auth/contexts/AuthContext';
import budgetGroupService from './api/budgetGroupService';
import BUDGET_GROUPS_QUERY_KEYS from './api/budgetGroupsQueryKeys';
import BudgetGroup from './BudgetGroup';
import CreateBudgetGroupDialog from './CreateBudgetGroupDialog';
import SortBudgetGroupsDialog from './SortBudgetGroupsDialog';

const BudgetGroups = ({ budgetId }) => {
  const authState = useAuth();
  const [openCreateBudgetGroupDialog, setOpenCreateBudgetGroupDialog] = useState(false);
  const [openSortBudgetGroupsDialog, setOpenSortBudgetGroupsDialog] = useState(false);
  const [openMore, setOpenMore] = useState(null);

  const handleMoreOpen = (event) => {
    setOpenMore(event.currentTarget);
  };

  const handleMoreClose = () => {
    setOpenMore(null);
  };

  const budgetGroupsQuery = useQuery({
    queryKey: [BUDGET_GROUPS_QUERY_KEYS.budgetGroups, budgetId],
    queryFn: () => getBudgetGroups()
  });

  const getBudgetGroups = async () => {
    const user = await authState.userManager.getUser();
    const budgetGroupsResponse = await budgetGroupService.getBudgetGroups(budgetId, user.access_token);

    return budgetGroupsResponse.data;
  }

  const handleSortOpen = () => {
    handleMoreClose();
    setOpenSortBudgetGroupsDialog(true);
  }

  const addBudgetGroup = () => {
    setOpenCreateBudgetGroupDialog(true);
  }

  return (
    <>
      {budgetGroupsQuery.isLoading && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '32px' }}>
          <CircularProgress size={100} />
        </Box>
      )}
      {budgetGroupsQuery.isError && (
        <Typography
          color="error"
          sx={{ mt: 3 }}
          variant="h6"
        >
          {budgetGroupsQuery.error.response
            ? <b>{budgetGroupsQuery.error.response.data.message}</b>
            : <b>{budgetGroupsQuery.error.message}</b>}
        </Typography>
      )}
      {budgetGroupsQuery.isSuccess &&
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <IconButton size="small" onClick={handleMoreOpen}>
              <MoreVertIcon />
            </IconButton>
            {/* TODO ab problem with correct position https://github.com/mui/material-ui/issues/8090 */}
            <Menu
              anchorEl={openMore}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={Boolean(openMore)}
              onClose={handleMoreClose}
            >
              <MenuItem onClick={handleSortOpen}>
                <ListItemIcon>
                  <SwapVertIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">
                    Sort groups
                  </Typography>
                </ListItemText>
              </MenuItem>
            </Menu>
            <Button
              startIcon={(
                <SvgIcon fontSize="small">
                  <AddIcon />
                </SvgIcon>
              )}
              variant="contained"
              onClick={addBudgetGroup}
            >
              Add group
            </Button>
          </Stack>
          <Grid
            container
            spacing={3}
          >
            {budgetGroupsQuery.data.map(budgetGroup => (
              <Grid
                xs={12}
                md={6}
                xl={4}
                key={budgetGroup.id}
              >
                <BudgetGroup budgetId={budgetId} budgetGroup={budgetGroup} />
              </Grid>
            ))}
          </Grid>
          <CreateBudgetGroupDialog openDialog={openCreateBudgetGroupDialog} setOpenDialog={setOpenCreateBudgetGroupDialog} budgetId={budgetId} />
          <SortBudgetGroupsDialog openDialog={openSortBudgetGroupsDialog} setOpenDialog={setOpenSortBudgetGroupsDialog} budgetId={budgetId} />
        </Stack>
      }
    </>
  );
}

export default BudgetGroups;
