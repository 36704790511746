import { useLocation } from 'react-router-dom';

import { Box, CircularProgress, Divider, Drawer, Stack, Typography, useMediaQuery } from '@mui/material';

import SideNavItem from './SideNavItem';
import { useUser } from '../auth/contexts/UserContext';
import { items } from './sections';

const SideNav = (props) => {
  const { open, onClose } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const userState = useUser();
  
  const isActive = (item) => {
    if (location.pathname === '/') {
      return item.paths.includes(location.pathname);
    }

    return item.paths.filter(p => p !== '/').some(p => location.pathname.includes(p));
  }

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.04)',
              borderRadius: 1,
              display: 'flex',
              justifyContent: 'space-between',
              mt: 2,
              p: '8px'
            }}
          >
            {userState.isPending && (
              <Box sx={{flex: 1, display: 'flex', flex: 1, justifyContent: 'center'}}>
                 <CircularProgress size={25}/>
              </Box>
            )}
            {userState.userInfo && (
              <Typography
                color="inherit"
                variant="subtitle1"
              >
                Hello {userState.userInfo.userName}!
              </Typography>
            )}
          </Box>
        </Box>
        <Divider sx={{ borderColor: 'neutral.700' }} />
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3
          }}
        >
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: 'none',
              p: 0,
              m: 0
            }}
          >
            {items.map((item) => {
              return (
                <SideNavItem
                  active={isActive(item)}
                  disabled={item.disabled}
                  icon={item.icon}
                  key={item.title}
                  path={item.defaultPath}
                  title={item.title}
                />
              );
            })}
          </Stack>
        </Box>
        <Divider sx={{ borderColor: 'neutral.700' }} />
        <Box
          sx={{
            px: 2,
            py: 3
          }}
        >
          <Typography
            color="neutral.100"
            variant="subtitle2"
          >
            Plan Expenses
          </Typography>
          <Typography
            color="neutral.500"
            variant="body2"
          >
            by artubabi
          </Typography>
        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.800',
            color: 'common.white',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.800',
          color: 'common.white',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default SideNav;
