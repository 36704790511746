const sortOrderAssigner = {
   assign: function(sortableItems){
    return sortableItems.map(sortableItem => {
      const index = sortableItems.indexOf(sortableItem);
      sortableItem.sortOrder = index + 1;
      return sortableItem;
    });
   }
};

export default sortOrderAssigner;