import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, IconButton, CircularProgress, MenuItem, ListItemIcon, ListItemText, Menu, Paper, useMediaQuery } from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import { useAuth } from '../../../auth/contexts/AuthContext';
import budgetService from '../api/budgetService';
import PATH from '../../../routing/routingPaths';
import BUDGETS_QUERY_KEYS from '../../budgets/api/budgetsQueryKeys';
import UpdateBudgetDialog from '../../budgets/UpdateBudgetDialog';
import DeleteBudgetDialog from '../../budgets/DeleteBudgetDialog';

const BudgetBase = ({ budgetId }) => {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const navigate = useNavigate();
  const authState = useAuth();
  const [openDeleteBudgetDialog, setOpenDeleteBudgetDialog] = useState(false);
  const [openUpdateBudgetDialog, setOpenUpdateBudgetDialog] = useState(false);
  const [openMore, setOpenMore] = useState(null);

  const handleMoreOpen = (event) => {
    setOpenMore(event.currentTarget);
  };

  const handleMoreClose = () => {
    setOpenMore(null);
  };

  const budgetQuery = useQuery({
    queryKey: [BUDGETS_QUERY_KEYS.budgets, budgetId],
    queryFn: () => getUserBudget(),
  });

  const getUserBudget = async () => {
    const user = await authState.userManager.getUser();
    const budgetResponse = await budgetService.getUserBudget(budgetId, user.access_token);

    return budgetResponse.data;
  }

  const handleDeleteBudget = () => {
    handleMoreClose();
    setOpenDeleteBudgetDialog(true);
  }

  const handleUpdateBudget = () => {
    handleMoreClose();
    setOpenUpdateBudgetDialog(true);
  }

  const handleBackToBudgets = () => {
    navigate(`${PATH.root}`)
  }

  return (
    <>
      {budgetQuery.isLoading && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '32px' }}>
          <CircularProgress size={100} />
        </Box>
      )}
      {budgetQuery.isError && (
        <Typography
          color="error"
          sx={{ mt: 3 }}
          variant="h6"
        >
          {budgetQuery.error.response
            ? <b>{budgetQuery.error.response.data.message}</b>
            : <b>{budgetQuery.error.message}</b>}
        </Typography>
      )}
      {budgetQuery.isSuccess &&
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: lgUp ? 'nowrap' : 'wrap', gap: '16px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <IconButton sx={{alignSelf: 'flex-start'}} onClick={handleBackToBudgets}>
              <BackIcon fontSize='large' />
            </IconButton>
            <Typography variant="h4" sx={{ wordBreak: 'break-word' }}>
              {budgetQuery.data.name}
            </Typography>
            <IconButton size="small" onClick={handleMoreOpen}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={openMore}
              open={Boolean(openMore)}
              onClose={handleMoreClose}
            >
              <MenuItem onClick={handleUpdateBudget}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">
                    Edit
                  </Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem onClick={handleDeleteBudget}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">
                    Delete
                  </Typography>
                </ListItemText>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: lgUp ? 'nowrap' : 'wrap', gap: '8px', flex: lgUp ? undefined : 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px', flex: lgUp ? undefined : 1 }}>
              <Paper sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', padding: '16px', flex: lgUp ? undefined : 1 }}>
                <Typography>
                  Start&nbsp;date&nbsp;
                </Typography>
                <Typography>
                  {budgetQuery.data.startDate ? format(new Date(budgetQuery.data.startDate), 'dd/MM/yyyy') : '-'}
                </Typography>
              </Paper>
              <Paper sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', padding: '16px', flex: lgUp ? undefined : 1 }}>
                <Typography>
                  End&nbsp;date&nbsp;
                </Typography>
                <Typography>
                  {budgetQuery.data.endDate ? format(new Date(budgetQuery.data.endDate), 'dd/MM/yyyy') : '-'}
                </Typography>
              </Paper>
            </Box>
            <Paper sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', padding: '16px', flex: lgUp ? undefined : 1 }}>
              <Typography>
                Saldo&nbsp;
              </Typography>
              <Box sx={{ display: 'flex', gap: '4px' }}>
                <Typography>
                  {budgetQuery.data.saldo.value}
                </Typography>
                <Typography>
                  {budgetQuery.data.saldo.currency}
                </Typography>
              </Box>
            </Paper>
          </Box>
          <DeleteBudgetDialog
            openDialog={openDeleteBudgetDialog}
            setOpenDialog={setOpenDeleteBudgetDialog}
            navigatePath={`${PATH.root}`}
            id={budgetQuery.data.id}
            name={budgetQuery.data.name} />
          <UpdateBudgetDialog
            openDialog={openUpdateBudgetDialog}
            setOpenDialog={setOpenUpdateBudgetDialog}
            id={budgetQuery.data.id}
            name={budgetQuery.data.name}
            startDate={budgetQuery.data.startDate}
            endDate={budgetQuery.data.endDate} />
        </Box>
      }
    </>
  );
}

export default BudgetBase;
