import { Box, Typography, useMediaQuery } from "@mui/material";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const DesktopIntro = () => {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const backgroundColor = 'radial-gradient(50% 50% at 50% 50%, #122647 0%, #090E23 100%)';

  return (
    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', background: backgroundColor, color: 'white' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', margin: '32px' }}>
        <Typography align="center" variant={lgUp ? "h4" : "h5"} color="green.main">
          Plan Expenses
        </Typography>
        <Typography align="center" variant="subtitle1">
          Take control of your finances
        </Typography>
        <Typography align="center">
          <AccountBalanceIcon sx={{ fontSize: lgUp ? '96px' : '48px' }} />
        </Typography>
      </Box>
    </Box>
  );
}

export default DesktopIntro;