import { SvgIcon } from '@mui/material';
import WalletIcon from '@mui/icons-material/Wallet';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import PATH from '../routing/routingPaths';

export const items = [
  {
    title: 'Budgets',
    paths: [PATH.root, `${PATH.root}${PATH.budgets}`],
    defaultPath: PATH.root,
    icon: (
      <SvgIcon fontSize="small">
        <WalletIcon />
      </SvgIcon>
    ),
  },
  {
    title: 'Account',
    paths: [`${PATH.root}${PATH.account}`],
    defaultPath: `${PATH.root}${PATH.account}`,
    icon: (
      <SvgIcon fontSize="small">
        <ManageAccountsIcon />
      </SvgIcon>
    )
  }
];
