import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URI;

const budgetService = {
  getUserBudget: async function (id, accessToken) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    return await axios.get(`${baseUrl}Budgets/GetUserBudget/${id}`, config);
  }
}

export default budgetService;