import { useState } from 'react';

import { Box, Card, CardContent, IconButton, Typography, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UpIcon from '@mui/icons-material/NorthEastRounded';
import DownIcon from '@mui/icons-material/SouthEastRounded';

import POSITION_TYPES from './api/positionTypes';
import UpdateBudgetGroupPositionDialog from './UpdateBudgetGroupPositionDialog';
import DeleteBudgetGroupPositionDialog from './DeleteBudgetGroupPositionDialog';

const BudgetGroupPosition = ({ budgetId, currency, budgetGroupId, position }) => {
  const [openDeleteBudgetGroupPositionDialog, setOpenDeleteBudgetGroupPositionDialog] = useState(false);
  const [openUpdateBudgetGroupPositionDialog, setOpenUpdateBudgetGroupPositionDialog] = useState(false);
  const [openMore, setOpenMore] = useState(null);

  const handleMoreOpen = (event) => {
    setOpenMore(event.currentTarget);
  };

  const handleMoreClose = () => {
    setOpenMore(null);
  };

  const handleDeleteBudgetGroupPosition = () => {
    handleMoreClose();
    setOpenDeleteBudgetGroupPositionDialog(true);
  }

  const handleUpdateBudgetGroupPosition = () => {
    handleMoreClose();
    setOpenUpdateBudgetGroupPositionDialog(true);
  }

  return (
    <>
      <Card key={position.id}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: '8px'}}>
          <Typography sx={{wordBreak: 'break-word'}}>
            {position.name}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flex: 1}}>
            <Typography>
              {position.price.value}&nbsp;
            </Typography>
            <Typography>
              {position.price.currency}&nbsp;
            </Typography>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              {position.type === POSITION_TYPES.INCOME && <UpIcon fontSize="small" color="green"/>}
              {position.type === POSITION_TYPES.EXPENSE && <DownIcon fontSize="small" color="error"/>}
            </Box>
            <IconButton size="small" onClick={handleMoreOpen}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={openMore}
              open={Boolean(openMore)}
              onClose={handleMoreClose}
            >
              <MenuItem onClick={handleUpdateBudgetGroupPosition}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">
                    Edit
                  </Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem onClick={handleDeleteBudgetGroupPosition}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">
                    Delete
                  </Typography>
                </ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </CardContent>
      </Card>
      <UpdateBudgetGroupPositionDialog
        openDialog={openUpdateBudgetGroupPositionDialog}
        setOpenDialog={setOpenUpdateBudgetGroupPositionDialog}
        budgetId={budgetId}
        currency={currency}
        budgetGroupId={budgetGroupId}
        id={position.id}
        name={position.name}
        type={position.type}
        value={position.price.value}
      />
      <DeleteBudgetGroupPositionDialog
        openDialog={openDeleteBudgetGroupPositionDialog}
        setOpenDialog={setOpenDeleteBudgetGroupPositionDialog}
        budgetId={budgetId}
        budgetGroupId={budgetGroupId}
        id={position.id}
        name={position.name}
        type={position.type}
      />
    </>
  );
}

export default BudgetGroupPosition;