import { Button, Stack, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAuth } from '../../../auth/contexts/AuthContext';
import BUDGET_GROUPS_QUERY_KEYS from './api/budgetGroupsQueryKeys';
import budgetGroupService from './api/budgetGroupService';
import size from '../../../theme/size';

const UpdateBudgetGroupDialog = ({ openDialog, setOpenDialog, id, budgetId, name }) => {
  const authState = useAuth();

  const handleClose = () => {
    formik.resetForm();
    updateBudgetGroupMutation.reset();
    setOpenDialog(false);
  };

  const handleCloseAfterUpdate = () => {
    updateBudgetGroupMutation.reset();
    setOpenDialog(false);
  }

  const queryClient = useQueryClient();
  const updateBudgetGroupMutation = useMutation({
    mutationFn: budgetGroupService.updateBudgetGroup,
    onSuccess: data => {
      queryClient.invalidateQueries({queryKey: [BUDGET_GROUPS_QUERY_KEYS.budgetGroups, budgetId], exact: true});
      handleCloseAfterUpdate();
    }
  });

  const handleUpdateBudgetGroup = async (values) => {
    const user = await authState.userManager.getUser();

    updateBudgetGroupMutation.mutate({
      id: id,
      budgetId: budgetId,
      name: values.name,
      accessToken: user.access_token
    });
  };
  
  const initialValues = {
    name: name
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      name: Yup
        .string()
        .max(255)
        .required('Name is required')
    }),
    onSubmit: async (values, helpers) => {
      try {
        handleUpdateBudgetGroup(values);
      }
      catch (error) {
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);

        helpers.setErrors({ submit: error.message });
      }
    }
  });

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" justifyContent="center" sx={{ p: 1, mr: 1, ml: 1, minWidth: size.dialogWidth }}>
          <DialogTitle sx={{mt: 1}}>
            {'Update budget group'}
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" justifyContent="center" spacing={3} sx={{mt: 3}}>
              <TextField
                error={!!(formik.touched.name && formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                label="Name"
                name="name"
                onChange={formik.handleChange}
                type="text"
                value={formik.values.name}
              />
              {formik.errors.submit && (
                <DialogContentText
                  color="error"
                  sx={{ mt: 3 }}
                  variant="body2"
                >
                  {formik.errors.submit}
                </DialogContentText>
              )}
              {updateBudgetGroupMutation.isError && (
                <DialogContentText
                  color="error"
                  sx={{ mt: '8px' }}
                  variant="body2" >
                  {updateBudgetGroupMutation.error.response
                    ? <b>{updateBudgetGroupMutation.error.response.data.message}</b>
                    : <b>{updateBudgetGroupMutation.error.message}</b>}
                </DialogContentText>
              )}
            </Stack>
          </DialogContent>
        </Stack>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={updateBudgetGroupMutation.isPending} type="submit" variant="contained">
            {updateBudgetGroupMutation.isPending ? "Updating..." : "Update"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default UpdateBudgetGroupDialog;