import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { v4 as uuid } from 'uuid';

import { useAuth } from './contexts/AuthContext';
import Loading from '../common/Loading';
import PATH from '../routing/routingPaths';

const Authorize = () => {
  const navigate = useNavigate();
  const authState = useAuth();

  useEffect(() => {
    async function signinRedirect(){
      try {
        await authState.userManager.signinRedirect({state: uuid()});
      }
      catch{
        navigate(`${PATH.root}${PATH.login}`)
      }
    }
    signinRedirect();
  });

  return <Loading title={'Authorizing'}/>
};

export default Authorize;