import { Routes, Route } from "react-router-dom"

import ProtectedRoute from "../auth/ProtectedRoute";
import Register from "../auth/Register";
import Login from "../auth/Login";
import Callback from "../auth/Callback"
import Authorize from "../auth/Authorize";
import Budgets from "../sections/budgets/Budgets";
import Budget from "../sections/budget/Budget";
import Account from "../sections/account/Account";

import PATH from "./routingPaths";
import NotFound from "../common/NotFound";

const Routing = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path={PATH.root} element={<Budgets />} />
        <Route path={PATH.budgets} element={<Budget />} />
        <Route path={`${PATH.budgets}/:id`} element={<Budget />} />
        <Route path={PATH.account} element={<Account />} />
      </Route>
      <Route path={PATH.authorize} element={<Authorize />} />
      <Route path={PATH.login} element={<Login />} />
      <Route path={PATH.callback} element={<Callback />} />
      <Route path={PATH.register} element={<Register />} />
      <Route path={PATH.notFound} element={<NotFound />} />
    </Routes>
  );
}

export default Routing;