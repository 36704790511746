import { useParams } from 'react-router-dom';

import { Box, Container, Stack } from '@mui/material';

import DashboardLayout from '../../dashboard/DashboardLayout';
import BudgetBase from './budgetBase/BudgetBase';
import BudgetGroups from './budgetGroup/BudgetGroups';
import { useState } from 'react';

const Budget = () => {
  const { id } = useParams();
  
  return (
    <>
      <DashboardLayout>
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
          <Container maxWidth="xl">
            <Stack spacing={3}>
              <BudgetBase budgetId={id} />
              <BudgetGroups budgetId={id} />
            </Stack>
          </Container>
        </Box>
      </DashboardLayout>
    </>
  );
}

export default Budget;
