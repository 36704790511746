import { useNavigate } from "react-router-dom";

import { Box, Button, SvgIcon, Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { ArrowBack } from "@mui/icons-material";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{
        background: 'radial-gradient(50% 50% at 50% 50%, #122647 0%, #090E23 100%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}>
        <Box sx={{ color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '32px' }}>
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Typography variant="subtitle1" color="green.main">Plan Expenses</Typography>
            <AccountBalanceIcon fontSize='small'/>
          </Box>
          <Typography variant='h4'>
            Page not found 
          </Typography>
          <Button
              startIcon={(
                <SvgIcon fontSize="small">
                  <ArrowBack />
                </SvgIcon>
              )}
              sx={{ mt: 3 }}
              variant="contained"
              onClick={() => navigate("/")}
            >
              Go back to budgets
            </Button>
        </Box>
      </Box>
    </>
  );
}

export default NotFound;