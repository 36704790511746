import { useState } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material";

import { useAuth } from "../../../auth/contexts/AuthContext";
import size from "../../../theme/size";
import SortableItems from "../../../common/sort/SortableItems";
import sortOrderAssigner from "../../../common/sort/sortOrderAssigner";
import budgetGroupPositionService from "./api/budgetGroupPositionService";
import BUDGET_GROUP_POSITIONS_QUERY_KEYS from "./api/budgetGroupPositionsQueryKeys";
import BUDGET_GROUPS_QUERY_KEYS from "../budgetGroup/api/budgetGroupsQueryKeys";

const SortBudgetGroupPositionsDialog = ({ openDialog, setOpenDialog, budgetId, budgetGroupId }) => {
  const authState = useAuth();
  const [sortOrderBudgetGroupPositions, setSortOrderBudgetGroupPositions] = useState();

  const handleClose = () => {
    updateSortOrderBudgetGroupPositionsMutation.reset();
    setOpenDialog(false);
  };

  const sortOrderBudgetGroupPositionsQuery = useQuery({
    queryKey: [BUDGET_GROUP_POSITIONS_QUERY_KEYS.sortBudgetGroupPositions, budgetId, budgetGroupId],
    queryFn: () => getSortOrderBudgetGroupPositions(),
    enabled: openDialog
  });

  const getSortOrderBudgetGroupPositions = async () => {
    const user = await authState.userManager.getUser();
    const response = await budgetGroupPositionService.getSortOrderBudgetGroupPositions(budgetId, budgetGroupId, user.access_token);
    setSortOrderBudgetGroupPositions(response.data.positions);

    return response.data;
  }

  const queryClient = useQueryClient();
  const updateSortOrderBudgetGroupPositionsMutation = useMutation({
    mutationFn: budgetGroupPositionService.updateSortOrderBudgetGroupPositions,
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: [BUDGET_GROUP_POSITIONS_QUERY_KEYS.sortBudgetGroupPositions, budgetId, budgetGroupId], exact: true });
      queryClient.invalidateQueries({ queryKey: [BUDGET_GROUPS_QUERY_KEYS.budgetGroups, budgetId], exact: true });
      handleClose();
    }
  });

  const handleUpdateSortOrderBudgetGroupPositions = async (values) => {
    const user = await authState.userManager.getUser();

    const budgetGroupPositions = sortOrderAssigner.assign(sortOrderBudgetGroupPositions);

    updateSortOrderBudgetGroupPositionsMutation.mutate({
      accessToken: user.access_token,
      budgetId: budgetId,
      budgetGroupId: budgetGroupId,
      positions: budgetGroupPositions
    });
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
    >
      <Stack direction="column" justifyContent="center" sx={{ p: 1, mr: 1, ml: 1, gap: '16px', minWidth: size.dialogWidth, maxWidth: size.dialogWidth, minHeight: size.dialogHeigth, maxHeight: size.dialogHeigth }}>
        <DialogTitle>
          {"Sort positions in group"}
        </DialogTitle>
        {sortOrderBudgetGroupPositionsQuery.isFetching && (
          <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={100} />
          </DialogContent>
        )}
        {!sortOrderBudgetGroupPositionsQuery.isFetching && sortOrderBudgetGroupPositionsQuery.isSuccess && (
          <DialogContent>
            <SortableItems
              sortableItems={sortOrderBudgetGroupPositions}
              setSortableItems={setSortOrderBudgetGroupPositions} />
          </DialogContent>
        )}

        {(sortOrderBudgetGroupPositionsQuery.isError || updateSortOrderBudgetGroupPositionsMutation.isError) && (
          <Box>
            {sortOrderBudgetGroupPositionsQuery.isError && (
              <DialogContentText
                color="error"
                sx={{ mt: '8px' }}
                variant="body2" >
                {sortOrderBudgetGroupPositionsQuery.error.response
                  ? <b>{sortOrderBudgetGroupPositionsQuery.error.response.data.message}</b>
                  : <b>{sortOrderBudgetGroupPositionsQuery.error.message}</b>}
              </DialogContentText>
            )}
            {updateSortOrderBudgetGroupPositionsMutation.isError && (
              <DialogContentText
                color="error"
                sx={{ mt: '8px' }}
                variant="body2" >
                {updateSortOrderBudgetGroupPositionsMutation.error.response
                  ? <b>{updateSortOrderBudgetGroupPositionsMutation.error.response.data.message}</b>
                  : <b>{updateSortOrderBudgetGroupPositionsMutation.error.message}</b>}
              </DialogContentText>
            )}
          </Box>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdateSortOrderBudgetGroupPositions} disabled={updateSortOrderBudgetGroupPositionsMutation.isPending} variant="contained">
            {updateSortOrderBudgetGroupPositionsMutation.isPending ? "Updating..." : "Update"}
          </Button>
        </DialogActions>
      </Stack>

    </Dialog>
  );
}

export default SortBudgetGroupPositionsDialog;