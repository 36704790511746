import { useState } from "react";

import { Box, Button, SvgIcon } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import BudgetGroupPosition from "./BudgetGroupPosition";
import CreateBudgetGroupPositionDialog from "./CreateBudgetGroupPositionDialog";

const BudgetGroupPositions = ({ budgetId, currency, budgetGroupId, positions }) => {
  const [openCreateBudgetGroupPositionDialog, setOpenCreateBudgetGroupPositionDialog] = useState(false);

  const addBudgetGroupPosition = () => {
    setOpenCreateBudgetGroupPositionDialog(true);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', paddingTop: '8px' }}>
      <Button
        startIcon={(
          <SvgIcon fontSize="small">
            <AddIcon />
          </SvgIcon>
        )}
        variant="contained"
        onClick={addBudgetGroupPosition}
      >
        Add
      </Button>
      {positions.map(p =>
        <BudgetGroupPosition key={p.id} budgetId={budgetId} currency={currency} budgetGroupId={budgetGroupId} position={p} />
      )}
      <CreateBudgetGroupPositionDialog
        openDialog={openCreateBudgetGroupPositionDialog}
        setOpenDialog={setOpenCreateBudgetGroupPositionDialog}
        budgetId={budgetId}
        currency={currency}
        budgetGroupId={budgetGroupId}
      />
    </Box>
  );
}

export default BudgetGroupPositions;