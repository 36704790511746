import { useState } from 'react';

import { Button, Card, CardActions, CardContent, CardHeader, Divider, Stack, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useAuth } from '../../auth/contexts/AuthContext';
import authService from '../../auth/api/authService';
import PasswordField from '../../common/PasswordField';

const AccountPassword = () => {
  const authState = useAuth();
  const [open, setOpen] = useState(false);
  const [dialogCurrentPassword, setDialogCurrentPassword] = useState('');
  const [dialogNewPassword, setDialogNewPassword] = useState('');
  const [dialogConfirmedNewPassword, setDialogConfirmedNewPassword] = useState('');
  const [dialogError, setDialogError] = useState('');

  const setDialogValues = (values) => {
    setDialogCurrentPassword(values.currentPassword);
    setDialogNewPassword(values.newPassword);
    setDialogConfirmedNewPassword(values.confirmedNewPassword);
  }

  const clearDialogValues = () => {
    setDialogCurrentPassword('');
    setDialogNewPassword('');
    setDialogConfirmedNewPassword('');
  }

  const handleClickOpen = (values) => {
    setDialogValues(values);
    setOpen(true);
  };

  const handleClose = () => {
    clearDialogValues();
    setDialogError('');
    setOpen(false);
  };

  const handleUpdatePassword = async () => {
    try {
      setDialogError('');
      const user = await authState.userManager.getUser();
      await authService.changePassword(dialogCurrentPassword, dialogNewPassword, dialogConfirmedNewPassword, user.access_token);
      authState.userManager.signoutRedirect();
    } catch (error) {
      setDialogError(error.response.data.message);
    }
  }

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmedNewPassword: ''
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      currentPassword: Yup
        .string()
        .max(255)
        .required('Current password is required'),
      newPassword: Yup
        .string()
        .max(255)
        .required('New password is required'),
      confirmedNewPassword: Yup
        .string()
        .max(255)
        .required('New password confirmation is required')
        .oneOf([Yup.ref('newPassword')], 'Your passwords are not equal')
    }),
    onSubmit: async (values, helpers) => {
      try {
        handleClickOpen(values);
      }
      catch (error) {
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);

        helpers.setErrors({ submit: error.message });
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader
          subheader="Update password"
          title="Password"
        />
        <Divider />
        <CardContent>
          <Stack
            spacing={3}
            sx={{ maxWidth: 400 }}
          >
            <PasswordField formik={formik} formikValue={'currentPassword'} label={'Password (Current)'}/>
            <PasswordField formik={formik} formikValue={'newPassword'} label={'Password (New)'}/>
            <PasswordField formik={formik} formikValue={'confirmedNewPassword'} label={'Password (Confirm)'}/>
          </Stack>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            type="submit">
            Update
          </Button>
        </CardActions>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Password will be changed and then login will be needed again
          </DialogContentText>
          {dialogError && (
            <DialogContentText
              color="error"
              sx={{ mt: '8px' }}
              variant="body2" >
              <b>{dialogError}</b>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdatePassword} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default AccountPassword;