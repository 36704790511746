import { useCallback } from 'react';
import { useLocation } from "react-router-dom";

import { Box, Divider, MenuItem, MenuList, Popover, Typography } from '@mui/material';

import { useAuth } from '../auth/contexts/AuthContext';
import { useUser } from '../auth/contexts/UserContext';

const AccountPopover = (props) => {
  const { anchorEl, onClose, open } = props;
  const location = useLocation();
  const authState = useAuth();
  const userState = useUser();

  const handleSignOut = useCallback(
    () => {
      onClose?.();
      authState.userManager.signoutRedirect();
    },
    [onClose, location]
  );

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2
        }}
      >
        <Typography variant="overline">
          Account
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {userState.userInfo && userState.userInfo.userName}
        </Typography>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: '8px',
          '& > *': {
            borderRadius: 1
          }
        }}
      >
        <MenuItem onClick={handleSignOut}>
          Sign out
        </MenuItem>
      </MenuList>
    </Popover>
  );
};

export default AccountPopover;
