import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import { useMutation, useQueryClient } from "@tanstack/react-query";

import budgetGroupPositionService from './api/budgetGroupPositionService';
import { useAuth } from '../../../auth/contexts/AuthContext';
import BUDGET_GROUPS_QUERY_KEYS from '../budgetGroup/api/budgetGroupsQueryKeys';
import BUDGETS_QUERY_KEYS from '../../budgets/api/budgetsQueryKeys';

const DeleteBudgetGroupPositionDialog = ({openDialog, setOpenDialog, id, budgetId, budgetGroupId, name, type}) => {
  const authState = useAuth();

  const handleClose = () => {
    deleteBudgetGroupPositionMutation.reset();
    setOpenDialog(false);
  };

  const queryClient = useQueryClient();
  const deleteBudgetGroupPositionMutation = useMutation({
    mutationFn: budgetGroupPositionService.deleteBudgetGroupPosition,
    onSuccess: data => {
      queryClient.invalidateQueries({queryKey: [BUDGET_GROUPS_QUERY_KEYS.budgetGroups, budgetId], exact: true})
      queryClient.invalidateQueries({queryKey: [BUDGETS_QUERY_KEYS.budgets, budgetId], exact: true})
      handleClose();
    }
  });

  const handleDeleteBudgetGroupPosition = async () => {
    const user = await authState.userManager.getUser();

    deleteBudgetGroupPositionMutation.mutate({
      id: id,
      budgetId: budgetId,
      budgetGroupId: budgetGroupId,
      accessToken: user.access_token
    });
  };

  return (
    <Dialog
        open={openDialog}
        onClose={handleClose}
      >
        <DialogTitle>
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {type} <b>{name}</b> will be removed with all content
          </DialogContentText>
          {deleteBudgetGroupPositionMutation.isError && (
            <DialogContentText
              color="error"
              sx={{ mt: '8px' }}
              variant="body2" >
              {deleteBudgetGroupPositionMutation.error.response
                ? <b>{deleteBudgetGroupPositionMutation.error.response.data.message}</b> 
                : <b>{deleteBudgetGroupPositionMutation.error.message}</b>}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDeleteBudgetGroupPosition} disabled={deleteBudgetGroupPositionMutation.isPending} variant="contained">
          {deleteBudgetGroupPositionMutation.isPending ? "Deleting..." : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default DeleteBudgetGroupPositionDialog;