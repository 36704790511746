import React, { useContext } from "react";

import { useQuery } from "@tanstack/react-query";

import authService from "../api/authService";
import { useAuth } from "./AuthContext";

const UserContext = React.createContext();

export const useUser = () => {
  return useContext(UserContext);
}

export const UserProvider = ({ children }) => {
  const authState = useAuth();

  const userInfoQuery = useQuery({
    queryKey: ['user-info'],
    queryFn: () => getUserInfo(),
    enabled: authState.isAuthenticated
  });

  const getUserInfo = async () => {
    const user = await authState.userManager.getUser();
    const userInfoResponse = await authService.getUserInfo(user.access_token);

    return userInfoResponse.data;
  }

  const state = {
    isPending: userInfoQuery.isPending,
    userInfo: userInfoQuery.data
  };

  return (
    <UserContext.Provider value={state}>
      {children}
    </UserContext.Provider>
  )
}