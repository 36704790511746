import { useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";

const PasswordField = ({ formik, formikValue, label }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleOnMouseDown = (event) => event.preventDefault();

  return (
    <TextField
      fullWidth
      error={!!(formik.touched[formikValue] && formik.errors[formikValue])}
      helperText={formik.touched[formikValue] && formik.errors[formikValue]}
      label={label}
      name={formikValue}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleOnMouseDown}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
      }}
      value={formik.values[formikValue]}
    />
  );
}

export default PasswordField;