const authSettings = {
  authority: process.env.REACT_APP_AUTH_AUTHORITY_URI,
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI,
  post_logout_redirect_uri: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
  response_type: process.env.REACT_APP_AUTH_RESPONSE_TYPE,
  scope: process.env.REACT_APP_AUTH_SCOPE,
  userinfo_endpoint: process.env.REACT_APP_AUTH_USERINFO_ENDPOINT,
  response_mode: process.env.REACT_APP_AUTH_RESPONSE_MODE,
  code_challenge_method: process.env.REACT_APP_AUTH_CODE_CHALLANGE_METHOD
};

export default authSettings;
