const PATH = {
  root: '/',
  budgets: 'budgets',
  account: 'account',
  authorize: 'auth/authorize',
  login: 'auth/login',
  callback: 'auth/callback',
  register: 'auth/register',
  notFound: '*'
}

export default PATH;